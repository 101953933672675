/* eslint-disable camelcase */
export function signInRequest(user_id, password, admin) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { user_id, password, admin },
  };
}

export function signInSuccess(token, user, admin) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, user, admin },
  };
}

export function signUpRequest(name, user_id, password, admin) {
  return {
    type: '@auth/SIGN_UP_REQUEST',
    payload: { name, user_id, password, admin },
  };
}

export function signFailure() {
  return {
    type: '@auth/SIGN_FAILURE',
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}
