import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  height: 100%;
  background: rgb(194, 194, 194);
  background: linear-gradient(
    90deg,
    rgba(194, 194, 194, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );

  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  margin-top: 10px;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 25px;
    font-weight: bold;
    color: black;

    &:hover {
      color: #a20000;
    }
  }

  img {
    width: 15%;
  }
`;
export const Content = styled.div`
  width: 100%;
  max-width: 315px;
  text-align: center;
  margin-top: 64px;

  form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    input {
      background: rgba(0, 0, 0, 0.1);
      border: 0;
      border-radius: 4px;
      height: 44px;
      padding: 0 15px;
      color: #fff;
      margin: 0 0 10px;
    }
  }

  #checkbox {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    color: #757575;
    font-size: 15px;
    font-weight: bold;
    label {
      margin-left: 10px;
    }
  }
  button {
    margin: 5px 0 0;
    height: 44px;
    background: #a20000;
    font-weight: bold;
    color: #fff;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    transition: background 0.2s;

    &:hover {
      background: ${darken(0.03, '#a20000')};
    }
  }
`;
