import axios from 'axios';
import baseURL from './server';

require('dotenv/config');

const api = axios.create({
  baseURL,
  proxy: false,
});

export default api;
