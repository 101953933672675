/* eslint-disable camelcase */
import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import history from '../../../services/history';
import api from '../../../services/api';

import { signInSuccess, signFailure } from './actions';

export function* signIn({ payload }) {
  try {
    const { user_id, password } = payload;

    const response = yield call(api.post, 'sessions', { user_id, password });

    const { token, user, admin } = response.data;

    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(signInSuccess(token, user, admin));

    if (user_id === 'tv') {
      history.push('/tv');
    } else if (user_id === 'totem') {
      history.push('/totem');
    } else {
      history.push('/dashboard');
    }
  } catch {
    yield put(signFailure());
    toast.error('Usuário/senha incorreto!');
  }
}

export function setToken({ payload }) {
  if (!payload) return;
  const { token } = payload.auth;
  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function* signUp({ payload }) {
  try {
    const { name, user_id, password, admin } = payload;

    yield call(api.post, 'users', {
      name,
      user_id,
      password,
      admin,
    });

    toast.success('Cadastro concluido com sucesso!');
  } catch (err) {
    toast.error('Falha no cadastro!');

    history.push('/');
  }
}

export function signOut() {
  history.push('/');
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
  takeLatest('@auth/SIGN_OUT', signOut),
]);
