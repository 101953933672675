/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input } from '@rocketseat/unform';
import { useDispatch } from 'react-redux';
import { FaChevronLeft } from 'react-icons/fa';
import { Container, Content, Header } from './styles';
import { signUpRequest } from '../../store/modules/auth/actions';

import logo from '../../images/logosobral.png';

export default function SignUp() {
  const [admin, setAdmin] = useState(false);
  const [name, setName] = useState('');
  const [user_id, setUser] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();

  function toggleChecked() {
    setAdmin(!admin);
  }

  function handleName(event) {
    setName(event.target.value);
  }
  function handleUser(event) {
    setUser(event.target.value);
  }
  function handlePassword(event) {
    setPassword(event.target.value);
  }

  function handleSubmit() {
    dispatch(signUpRequest(name, user_id, password, admin));

    setName('');
    setUser('');
    setPassword('');
  }
  return (
    <Container>
      <Header>
        <Link to="/dashboard">
          <div>
            <FaChevronLeft /> Voltar
          </div>
        </Link>
        <img src={logo} alt="Sobral" />
      </Header>
      <Content>
        <h1>Cadastre novo usuário</h1>

        <Form onSubmit={handleSubmit}>
          <Input
            type="text"
            placeholder=" Nome"
            name="name"
            value={name}
            onChange={handleName}
            required
          />
          <Input
            type="text"
            placeholder=" Usuário"
            name="user_id"
            value={user_id}
            onChange={handleUser}
            required
          />
          <Input
            type="password"
            placeholder="Senha"
            name="password"
            value={password}
            onChange={handlePassword}
            required
          />

          <div id="checkbox">
            <Input
              type="checkbox"
              id="admin"
              name="admin"
              checked={admin}
              onChange={toggleChecked}
            />
            <label htmlFor="admin">ADMIN</label>
          </div>
          <button type="submit">Cadastrar usuário</button>
        </Form>
      </Content>
    </Container>
  );
}
